<template>
    <div>
        <section class="py-30 start">
            <div class="row">
                <div class="container p-0 py-5">
                    <div class="row px-5 px-md-4">
                        <div class="col-12 col-md-7">
                            <h1 class="heading-1 font-weight-bold">
                                About Side Hustle
                            </h1>
                            <p class="mt-5">
                                Side Hustle is a talent management platform that
                                is aimed at reducing the rate of youth
                                unemployment and poverty in Nigeria, by
                                empowering youths (undergraduates, graduates and
                                youth corp members) to learn from the best
                                minds, execute their ideas and earn a living by
                                offering their services to businesses.
                                <br /><br />
                                Not only do we empower youth with the needed
                                skills to help them earn a living in the
                                society, we also connect them with the right
                                businesses that need their skills to help them
                                grow their business.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "About",
};
</script>

<style></style>
